// // store.ts
// import { configureStore } from '@reduxjs/toolkit';
// import startApplicationReducer from './slices/startApplicationSlices';
// import socketReducer from './slices/socketSlice' // Import your slice reducer

// const store = configureStore({
//   reducer: {
//     startApplication: startApplicationReducer,
//     socket: socketReducer,
//     // Add other reducers here if any
//   },
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

// export default store;

// store.ts
// store.ts
import { configureStore } from '@reduxjs/toolkit';
import socketReducer from './slices/socketSlice';
import startApplicationReducer from './slices/startApplicationSlices';
import applicationSubmittedReducer from './slices/applicationSubmittedSlice';

const store = configureStore({
  reducer: {
    startApplication: startApplicationReducer,
    socket: socketReducer,
    applicationSubmitted: applicationSubmittedReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

