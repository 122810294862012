import React from 'react';
import styles from './SubHeader.module.css'

interface SubHeadingprops {
    heading: string;
    subheading: string;
    
  }
const SubHeader: React.FC<SubHeadingprops> = ({heading, subheading}) => {
  return (
    <div className={styles.formContainer}>
      <p>{heading}</p>
    <p>{subheading}</p>
    </div>
  )
}

export default SubHeader
