import React from 'react';
import smallbanner from './../../icons/smallBanner.svg';
import styles from './SmallBanner.module.css'

const SmallBanner = () => {
  return (
    <div className={styles.smallBannerContainer}>
      <img src={smallbanner}/>
    </div>
  )
}

export default SmallBanner
