import React, { useEffect } from "react";
import styles from "./ApplicationSubmitted.module.css";
import applicationSubmitted from "../../../icons/applicationSubmitted.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const ApplicationSubmitted = () => {
  const socket = useSelector((state: RootState) => state.socket.socket);
  useEffect(() => {
    socket?.close();
  }, []);
  return (
    <div className={styles.applicationSubmittedContainer}>
      <p>Your request has been successfully submitted.</p>
      <img src={applicationSubmitted} alt="applicationSubmitted" />
      <div className={styles.applicationSubmittedFooter}>
        <p className={styles.applicationSubmittedEvaluation}>
          We are evaluating your request.
        </p>
        {/* <p>Click below to check your application status.</p> */}
        <span>
          For any queries reach us at <p>brands@klubworks.com</p>
        </span>
      </div>
    </div>
  );
};

export default ApplicationSubmitted;
