import "./index.css";
import { useEffect, useState } from "react";
import Layout from "./containers/Layout";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Banner from "./components/Banner";
import { AudioDeviceConfig, useConversation } from "vocode";

export default function App() {
  const [pageType, setPageType] = useState("banner");
  const [audioDeviceConfig, setAudioDeviceConfig] = useState<AudioDeviceConfig>(
    {},
  );
  const { status, start, stop, analyserNode, socket } = useConversation({
    backendUrl: "wss://nprd-auth.klubworks.com/conversation",
    // backendUrl: "ws://127.0.0.1:8005/conversation",
    subscribeTranscript: false,
    audioDeviceConfig,
  });
  const startApplication = useSelector(
    (state: RootState) => state.startApplication.startApplication,
  );

  const handlePageChange = (page: string) => {
    setPageType(page);
  };
  useEffect(() => {
    if (startApplication === true) {
      handlePageChange("homepage");
    }
  }, [startApplication]);
  const renderContent = () => {
    switch (pageType) {
      case "homepage":
        return <Layout />;
      case "banner":
        return (
          <Banner
            status={status}
            start={start}
            stop={stop}
            analyserNode={analyserNode}
            socket={socket}
          />
        );
    }
  };
  return <>{renderContent()}</>;
}
