// startApplicationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import applicationSubmittedReducer from '../reducers/applicationSubmitted';

interface ApplicationSubmittedState {
  applicationSubmitted: boolean;
}

const initialState: ApplicationSubmittedState = {
  applicationSubmitted: false,
};

const applicationSubmittedSlice = createSlice({
  name: 'applicationSubmitted',
  initialState,
  reducers: {
    setApplicationSubmitted: (state, action: PayloadAction<boolean>) => {
      state.applicationSubmitted = action.payload;
    },
  },
});

export const { setApplicationSubmitted } = applicationSubmittedSlice.actions;
export const selectApplicationSubmitted = (state: { applicationSubmitted: ApplicationSubmittedState }) => state.applicationSubmitted.applicationSubmitted;

export default applicationSubmittedSlice.reducer;
