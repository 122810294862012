import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, VStack, Spinner } from "@chakra-ui/react";
import { useConversation, AudioDeviceConfig } from "vocode";
import MicrophoneIcon from "../../../components/MicrophoneIcon";
import AudioVisualization from "../../../components/AudioVisualisation";
import { setStartApplication } from "../../../redux/slices/startApplicationSlices";
import { setSocket } from "../../../redux/slices/socketSlice";

interface BannerProps {
  status: string;
  start: () => void;
  stop: () => void;
  socket: any;
  analyserNode: any;
}
const Conversation: React.FC<BannerProps> = ({
  status,
  start,
  stop,
  socket,
  analyserNode,
}) => {
  let transcripts: any[] = [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      socket.addEventListener("message", handleSocketMessage);
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      dispatch(setSocket(socket));
    }
  }, [dispatch, socket]);

  const handleSocketMessage = async (event: any) => {
    if (event != null) {
      const socketResponse = JSON.parse(event.data);
      if (socketResponse.type === "form_response") {
        try {
          const message = JSON.parse(socketResponse.data);
          if (message["start_application"] === true) {
            dispatch(setStartApplication(true));
          }
        } catch (err) {}
      }
    }
  };

  // useEffect(() => {
  //   if (startApplciation) {
  //     dispatch(setStartApplication({ start: startApplciation }));
  //   }
  // }, [dispatch, startApplciation]);

  // const sendMessage = () => {
  //   if (!socket) {
  //     console.error('WebSocket connection not established.');
  //     return;
  //   }

  //   const message = {
  //     type: 'your_message_type',
  //     data: {'name': string},
  //   };

  //   socket.send(JSON.stringify(message));
  // };
  return (
    <>
      {analyserNode && <AudioVisualization analyser={analyserNode} />}
      <Button
        variant="link"
        disabled={["connecting", "error"].includes(status)}
        onClick={status === "connected" ? stop : start}
        position="fixed"
        bottom={"0"}
        left="50%"
        transform={"translate(-50%, -50%)"}
        backgroundColor={"transparent"}
        borderColor={"transparent"}
      >
        <Box boxSize={75}>
          <MicrophoneIcon color={"#ddfafa"} muted={status !== "connected"} />
        </Box>
      </Button>
      <Box boxSize={50} />
      {status === "connecting" && (
        <Box
          position={"absolute"}
          top="57.5%"
          left="50%"
          transform={"translate(-50%, -50%)"}
          padding={5}
        >
          <Spinner color="#FFFFFF" />
        </Box>
      )}
      {transcripts.length > 0 && (
        <VStack
          width="35%"
          position="absolute"
          top={"50%"}
          height={"45%"}
          left="2%"
          alignItems="left"
          overflowY="auto"
        >
          {transcripts.map((item, index) => {
            return (
              <Box key={"t" + index.toString()} color="white">
                {item.sender}: {item.text}
              </Box>
            );
          })}
        </VStack>
      )}
    </>
  );
};

export default Conversation;
