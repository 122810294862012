// LottieAnimation.js
import React from "react";
import Lottie from "react-lottie";
import Loader from "../../icons/Animation - 1719921885477.json"; // replace with your animation file path

const LottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return <Lottie options={defaultOptions} height={'40px'} width={'135px'} style={{marginLeft:'-2.4rem'}} />;
};

export default LottieAnimation;
