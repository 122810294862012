import React from 'react';
import styles from './FormButton.module.css'

// Define the prop types
type FormButtonProps = {
  text: string;
  onClick?: any;
};
const FormButton: React.FC<FormButtonProps> = ({text, onClick}) => {
  return (
    <button className={styles.buttonContainer} onClick={onClick}>
        {text}
    </button>
  )
}

export default FormButton
