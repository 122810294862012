import React, { forwardRef } from 'react';
import styles from './InputTextField.module.css';

interface InputTextFieldProps {
  label: string;
  placeholder: string;
  id?: string;
  value?: any;
  disable?: boolean;
  onChange?: any;
  ref?: any;
}

const InputTextField: React.FC<InputTextFieldProps> = forwardRef(({label, placeholder, id, value, disable=true,onChange} ,ref) => {
  return (
    <div className={styles.inputContainer} id={id}>
      <p>{label}</p>
      <input placeholder={placeholder} value={value} disabled={false} onChange={(e) => onChange(id, e.target.value)} ref={ref as React.RefObject<HTMLInputElement>}/>
    </div>
  )
});

export default InputTextField
