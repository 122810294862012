import styles from "./layout.module.css";
import logo from "../../icons/logo.svg";
import signup from "../../icons/signup.svg";
import brandDetails from "../../icons/brandDetails.svg";
import buisnessDetails from "../../icons/buisnessDetails.svg";
import mail from "../../icons/mail.svg";
import Forms from "../BrandOnboardingJourney/Forms";
import SmallBanner from "../../components/SmallBanner";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Layout = () => {
  const applciationSubmitted = useSelector(
    (state: RootState) => state.applicationSubmitted.applicationSubmitted
  );
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div>
            <img alt="logo" src={logo} />
          </div>
          <div className={styles.bannerContent}>
            <p className={styles.bannerContentHeading}>
              Unlock funds with your voice
            </p>
            <p className={styles.bannerContentSubHeading}>
              Provide the required details to us, so that we can provide you a
              better offer based on your requirements.
            </p>
            <div className={styles.bannerContentDesc}>
              {/* <span><img src={signup} alt='signup'/>Signup</span>
          <span> <img src={brandDetails} alt='brandDetails'/>Brand details</span>
          <span> <img src={buisnessDetails} alt='buisnessDetails'/>Business details</span> */}
            </div>
          </div>
          <div className={styles.footerContent}>
            <p>Need help?</p>
            <span>
              <img src={mail} alt="mail" />
              <p className={styles.footerContentSubHeading}>Reach out to us</p>
            </span>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <Forms applciationSubmitted={applciationSubmitted} />
        </div>
      </div>
      {!applciationSubmitted && <SmallBanner />}
    </div>
  );
};
export default Layout;
