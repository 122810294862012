import React, { useState } from "react";
import styles from "./forms.module.css";
import PersonDetails from "../../../components/Forms/PersonDetails";
import BrandDetails from "../../../components/Forms/BrandDetails";
import ApplicationSubmitted from "../../../components/Forms/ApplicationSubmitted";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import OTPScreen from "../../../components/Forms/OTPScreen";
import LeadGeneration from "../../../components/Forms/LeadGeneration";

interface ForrmProps {
  applciationSubmitted: boolean;
}
const Forms: React.FC<ForrmProps> = ({ applciationSubmitted }) => {
  return (
    <>
      {!applciationSubmitted ? (
        <div className={styles.formContainer}>
          <LeadGeneration />
          {/* <PersonDetails /> */}
          {/* <BrandDetails /> */}
          {/* <OTPScreen /> */}
        </div>
      ) : (
        <div className={styles.ApllicationSubmiiterFormContainer}>
          <ApplicationSubmitted />
        </div>
      )}
    </>
  );
};

export default Forms;
