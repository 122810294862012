import React from "react";
import klubEcllipse from "../../icons/klubEcllipse.svg";
import klub from "../../icons/klub.svg";
import Conversation from "../../containers/ConvoAi/Conversation";
import styles from "./banner.module.css";

interface BannerProps {
  status: string;
  start: () => void;
  stop: () => void;
  socket: any;
  analyserNode: any;
}
const Banner: React.FC<BannerProps> = ({
  status,
  start,
  stop,
  socket,
  analyserNode,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.welcomeContainer}>
        <div className="mt-7">
          <p className={styles.heading}>Welcome to Klub</p>
          <p className={styles.subHeading}>
            I&apos;m Lily, your personal assistant
          </p>
        </div>

        <div className={styles.canvasContainer}>
          <Conversation
            status={status}
            start={start}
            stop={stop}
            analyserNode={analyserNode}
            socket={socket}
          />
          <img
            alt="klubBg"
            src={klubEcllipse}
            style={{ position: "absolute" }}
          />
          <img alt="KlubK" src={klub} style={{ position: "absolute" }} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
