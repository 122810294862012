// startApplicationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StartApplicationState {
  startApplication: boolean;
}

const initialState: StartApplicationState = {
  startApplication: false,
};

const startApplicationSlice = createSlice({
  name: 'startApplication',
  initialState,
  reducers: {
    setStartApplication: (state, action: PayloadAction<boolean>) => {
      state.startApplication = action.payload;
    },
  },
});

export const { setStartApplication } = startApplicationSlice.actions;
export const selectStartApplication = (state: { startApplication: StartApplicationState }) => state.startApplication.startApplication;

export default startApplicationSlice.reducer;
