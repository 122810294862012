import { AnimatePresence } from "framer-motion";

const MicrophoneIcon = ({
  muted,
  color,
}: {
  muted: boolean;
  color: string;
}) => {
  return (
    <AnimatePresence>
      {!muted ? (
        <svg
          width="70"
          height="70"
          viewBox="0 0 73 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_72_1401)">
            <rect
              x="17"
              y="17"
              width="49"
              height="49"
              rx="24.5"
              fill="#0F117C"
            />
            <g clip-path="url(#clip0_72_1401)">
              <path
                d="M41.5006 23.5334C40.201 23.5334 38.9547 24.0496 38.0358 24.9686C37.1168 25.8875 36.6006 27.1338 36.6006 28.4334V41.5001C36.6006 42.7996 37.1168 44.0459 38.0358 44.9649C38.9547 45.8838 40.201 46.4001 41.5006 46.4001C42.8001 46.4001 44.0465 45.8838 44.9654 44.9649C45.8843 44.0459 46.4006 42.7996 46.4006 41.5001V28.4334C46.4006 27.1338 45.8843 25.8875 44.9654 24.9686C44.0465 24.0496 42.8001 23.5334 41.5006 23.5334V23.5334Z"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M52.934 38.2333V41.5C52.934 44.5323 51.7295 47.4404 49.5853 49.5846C47.4411 51.7288 44.533 52.9333 41.5007 52.9333C38.4684 52.9333 35.5603 51.7288 33.4161 49.5846C31.272 47.4404 30.0674 44.5323 30.0674 41.5V38.2333"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M41.5 52.9333V59.4667"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M34.9668 59.4667H48.0335"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_72_1401"
              x="0.666667"
              y="0.666667"
              width="81.6667"
              height="81.6667"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4.9"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_72_1401"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5.71667" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.278431 0 0 0 0 0.278431 0 0 0 0 0.501961 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_72_1401"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_72_1401"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_72_1401">
              <rect
                width="39.2"
                height="39.2"
                fill="white"
                transform="translate(21.9004 21.9)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="83"
          height="83"
          viewBox="0 0 83 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_72_1321)">
            <rect
              x="17"
              y="17"
              width="49"
              height="49"
              rx="24.5"
              fill="#0F117C"
            />
            <g clip-path="url(#clip0_72_1321)">
              <path
                d="M23.5342 23.5334L59.4675 59.4667"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M36.6006 36.6002V41.5002C36.6014 42.4687 36.8893 43.4152 37.4278 44.2202C37.9662 45.0252 38.7312 45.6526 39.626 46.0231C40.5208 46.3936 41.5054 46.4907 42.4553 46.3019C43.4053 46.1132 44.278 45.6472 44.9633 44.9628M46.4006 37.1555V28.4335C46.4018 27.2183 45.9515 26.0461 45.137 25.1443C44.3225 24.2425 43.202 23.6755 41.993 23.5534C40.784 23.4313 39.5728 23.7627 38.5944 24.4834C37.6161 25.2041 36.9404 26.2626 36.6986 27.4535"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.6667 49.585C48.0722 51.2125 46.0279 52.3265 43.7959 52.784C41.5639 53.2416 39.2461 53.0219 37.1399 52.1531C35.0336 51.2842 33.235 49.8059 31.9747 47.9078C30.7145 46.0097 30.05 43.7783 30.0667 41.5V38.2333M52.9334 38.2333V41.5C52.9328 42.1737 52.8727 42.8459 52.7537 43.509"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M41.5 52.9333V59.4667"
                stroke="#F3F4F6"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M34.9668 59.4667H48.0335"
                stroke="white"
                stroke-width="3.26667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_72_1321"
              x="0.666667"
              y="0.666667"
              width="81.6667"
              height="81.6667"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4.9"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_72_1321"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="5.71667" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.278431 0 0 0 0 0.278431 0 0 0 0 0.501961 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_72_1321"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_72_1321"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_72_1321">
              <rect
                width="39.2"
                height="39.2"
                fill="white"
                transform="translate(21.9004 21.9)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </AnimatePresence>
  );
};

export default MicrophoneIcon;
