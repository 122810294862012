import React, { useEffect, useRef, useState } from "react";
import styles from "./LeadGeneration.module.css";
import SubHeader from "../SubHeader";
import InputTextField from "../../../molecules/InputTextField";
import FormButton from "../../../molecules/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setApplicationSubmitted } from "../../../redux/slices/applicationSubmittedSlice";
import LottieAnimation from "../../LottieAnimation";

const LeadGeneration = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state: RootState) => state.socket.socket);
  const [apiCall, setApiCall] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    brandName: "",
  });
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const brandNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const handleMessage = (event: MessageEvent) => {
        const messageData = JSON.parse(event.data);
        if (messageData.type === "form_response") {
          setShowLoader(false);
          try {
            const data = JSON.parse(messageData.data);
            if (disabled === true) {
              setFormData((prevFormData) => ({
                ...prevFormData,
                ...data,
              }));
              setShowLoader(true);
            }
            if (data["end_application"] === true) {
              setApiCall(true);
            }
          } catch (err) {}
        }
      };

      socket.addEventListener("message", handleMessage);
      return () => {
        socket.removeEventListener("message", handleMessage);
      };
    } else {
      console.error("WebSocket is not open.");
    }
  }, [socket, disabled]);

  useEffect(() => {
    if (apiCall) {
      handleApiCall();
    }
  }, [apiCall]);

  useEffect(() => {
    setDisabled(!formData.brandName);

    if (formData.name && !formData.email) {
      emailRef.current?.focus();
    } else if (formData.email && !formData.number) {
      numberRef.current?.focus();
    } else if (formData.number && !formData.brandName) {
      brandNameRef.current?.focus();
    }
  }, [formData.name, formData.email, formData.number, formData.brandName]);

  const handleApiCall = async () => {
    try {
      const response = await fetch(
        `https://staging-api.klubworks.com/brand/v1/leads/signup`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, */*",
            Tenant: "IN",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile: "+91" + formData.number,
            tag: [
              { name: "utm_id", value: "AI_KAM" },
              { name: "brand_name", value: formData.brandName },
            ],
            email: formData.email,
            name: formData.name,
            consent: true,
          }),
        }
      );
      const data = await response.json();
      if (!response.ok && data?.statusCode !== 409) {
        setShowSubmitButton(true);
      } else if (data?.statusCode === 409) {
        dispatch(setApplicationSubmitted(true));
      } else {
        dispatch(setApplicationSubmitted(true));
      }
    } catch (error) {
      setShowSubmitButton(true);
      console.error("error", error);
    }
  };

  const handleOnchange = (field: any, value: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  return (
    <div className={styles.formContainer}>
      <SubHeader
        heading="Let’s get started,"
        subheading="Tell us about yourself"
      />
      {showLoader && <LottieAnimation />}
      <div className={styles.formInputsContainer}>
        <InputTextField
          placeholder={"Enter Name"}
          label={"What’s your name?"}
          id="name"
          value={formData.name}
          disable={disabled}
          onChange={handleOnchange}
          ref={nameRef}
        />
        <InputTextField
          placeholder={"Enter Email"}
          label={"What’s your e-mail?"}
          id="email"
          value={formData.email}
          disable={disabled}
          onChange={handleOnchange}
          ref={emailRef}
        />
        <InputTextField
          placeholder={"Enter Mobile number"}
          label={"What’s your mobile number"}
          id="number"
          value={formData.number}
          disable={disabled}
          onChange={handleOnchange}
          ref={numberRef}
        />
        <InputTextField
          placeholder={"Enter Brand Name"}
          label={"What’s your Brand Name"}
          id="brandName"
          value={formData.brandName}
          disable={disabled}
          onChange={handleOnchange}
          ref={brandNameRef}
        />
      </div>
      {showSubmitButton && <FormButton text="Submit" onClick={handleApiCall} />}
    </div>
  );
};

export default LeadGeneration;
